<template>
  <!-- 协议列表 -->
  <div> 
    <headbox 
           title="关于我们" 
          :isbackPath="true"
          path="person"></headbox>
<!--      <img  :src="img" alt="" v-if="!loading">-->
    <div class="profile" v-if="!loading">
      <h2 class="title">深圳市{{GLOBAL}}珠宝有限责任公司</h2>
      <p class="content">
        深圳市{{GLOBAL}}珠宝有限责任公司是以贵金属交易为主营业务的专业机构，主营&quot;回收提纯&quot;和&quot;兑换销售&quot;两项业务体系。
      </p>
      <img src="@/assets/images/about.png" alt="">
      <p class="content">
        深圳市{{GLOBAL}}珠宝有限责任公司自创立至今，坚持&quot;诚实、诚信、诚恳&quot;的原则。在运营模式上为客户提供更加快捷的服务为根本，经过不断的努力改进，形成具有{{GLOBAL}}特色的报价系统和客户定价、结价、提货和存货的服务模式。针对客户采用一站式服务，优化交易中的繁琐环节，使客户体验到便捷贴心的服务。
      </p>
      <p class="content">
        {{GLOBAL}}以成为中国具有行业影响力的贵金属服务企业为宗旨，为国内贵金属销售与回购打造专业的交易平台，以便捷的交易方式和诚信真实的态度服务于广大的贵金属从业者！
      </p>
    </div>
      <center id="loadingbox" v-if="loading">
        <van-loading  size="70px" />
      </center>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import * as commonpro from "@/utils/api";
export default {
  data() {
    return {
          loading:false,
          img:''
    };
  },
 async mounted(){
   this.loading = false
   // let res = await commonpro.getAboutPhoto()
   // res.forEach(item => {
   //   if (item.eventType == "ABOUT") {
   //        this.img = item.img
   //        this.loading = false
   //   }
   // });
  },
  components: {
    headbox
  }
};
</script>
<style lang="scss" scoped="true">
#loadingbox {
  margin-top: 150px;
}
::v-deep .van-collapse-item__title:after {
  content: none !important;
}
::v-deep .van-collapse-item__content {
  padding: 0;
}
::v-deep .van-collapse {
  margin: 0 30px;
}
::v-deep .van-icon {
  font-size: 36px;
  margin-top: 35px;
  transform: translate(-50%);
}
::v-deep .van-cell {
  border-bottom: 1px solid #cccccc;
  padding: 0;
}
::v-deep .van-cell__title {
  color: #292929;
  font-size: 32px;
  // margin: 0 30px;
  padding: 35px 0;
  padding-left: 20px;
}
.maxcenter {
  line-height: 48px;
  // margin: 0 30px;
  padding: 30px 0;
  font-size: 26px;
  border-bottom: 1px solid #cccccc;
}
.silderarrow {
  transform: rotate(180deg);
}
.list {
}
.cell > div {
  //  width: 90%;
  //  text-align: right;
  margin-left: 0.3rem;
  //  text-align: center;
  //   vertical-align: middle;
}
img {
  width: 100%;
}
.title {
  padding-top: 40px;
  color: #242242;
  font-size: 36px;
  text-align: center;
}

.content {
  color: #6f6f6f;
  text-align: left;
  padding: 35px 50px 0 50px;
  font-size: 30px;
  line-height: 1.6;
  font-weight: 500;
}
</style>
